@import "./theme.scss";

.select-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;

    cursor: pointer;

    label {
        font-size: 10pt !important;
        line-height: 150%;
        font-weight: bold;
    }

    .select-value {
        user-select: none;
        height: 100%;
        color: $color-1;
        padding: 1rem;
        border: $border-1;
        border-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s,
            box-shadow 0.2s;
        outline: none;
        font-family: 'Nunito', sans-serif;

        .drop-down-arrow {
            position: absolute;
            right: 15px;
            top: 45px;
            bottom: 0;
        }

    }

    .select-options {
        display: none;
        width: 100%;
        background-color: $bg-color-1;
        user-select: none;

        &#open {
            display: flex;
            top: 100%;
            position: absolute;
            z-index: 100;
            z-index: 100000;
        }

        .select-options-inner {
            width: 100%;
            margin-top: 5px;
            border: $border-1;
            border-radius: 6px;
            overflow: hidden;

            .select-option {
                padding: 1rem;
                transition: background-color 0.2s, color 0.2s, border-color 0.2s,
                    box-shadow 0.2s;
                outline: none;
                cursor: pointer;

                &:hover {
                    background-color: $a-color-1;
                    color: $bg-color-2;
                }
            }

        }
    }
}