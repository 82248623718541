.list {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px;
    padding-left: 0px;
    padding-right: 0px;
    gap: 8px;
}