@import './theme.scss';

.auth-bubble {
    .user-profile {
        min-width: fit-content;
        max-width: fit-content;
        height: fit-content;
        padding: 10px;
        padding-right: 15px;
        background-color: $color-2;
        border: $border-1;
        box-shadow: $box-shadow-1;
        border-radius: 8px;
        pointer-events: all;
        display: flex;
        gap: 10px;
        cursor: pointer;
        transition: all ease-in-out 0.2s;
        position: relative;
        justify-content: space-between;
        align-items: center;

        &:hover {
            background-color: $bg-color-2;
            box-shadow: $box-shadow-1;
        }

        .dp {
            width: 25px;
            height: 25px;
            background-position: center;
            background-size: cover;
            border-radius: 100%;
        }

        .details .name {
            white-space: nowrap;
        }
    }
}