@import "./theme.scss";

.members-header {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;

    .add-member {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: $a-color-1
        }
    }
}


.members {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.team-member-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .right-opts {
        display: flex;
        align-items: center;
        gap: 8px;

        .set-lead,
        .remove {
            cursor: pointer;
            width: 25px;
            height: 25px;
            border-radius: 100%;
            border: $border-1;
            // background-color: $a-color-1;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.2s ease-in-out;
        }

        .set-lead {
            color: #FFD700;

            &:hover {
                background-color: #FFD700;
                color: $color-1;
            }
        }

        .remove {
            color: #FF0000;

            &:hover {
                background-color: #FF0000;
                color: $color-1;
            }
        }

    }

    .dp {
        max-width: 25px;
        max-height: 25px;
        overflow: hidden;
        background-color: $a-color-1;
        width: 25px;
        height: 25px;

        background-position: center;
        background-size: cover;
        border-radius: 100%;
    }

}