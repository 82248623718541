@import "./theme.scss";


.agency-list-select {
    max-height: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 30px;
    margin-top: 8px;
    overflow-y: scroll;


    .agency-list-item {
        display: flex;
        align-items: center;
        padding: 8px;
        border-radius: $border-radius-1;
        cursor: pointer;
        transition: 0.2s ease-in-out all;
        background-color: $bg-color-2;

        &.selected {
            background-color: $a-color-1 !important;
            color: $bg-color-2 !important;
        }

        &:hover,
        &.active {
            background-color: $a-color-1;
            color: $bg-color-2;
        }
    }
}