@import "./theme.scss";

.button {
    // width: 100%;
    // margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    font-size: 0.9rem !important;
    cursor: pointer;
    user-select: none;
    text-align: center;
    overflow: hidden;
    position: relative;
    border: 1px solid $a-color-1;
    background: $a-color-1;
    color: $bg-color-2;
    border-radius: 5px;
    padding: 1rem 2rem;
    font-weight: 600;
    transition: all 0.2s ease-in-out;

    &:hover {
        opacity: 0.95;
        box-shadow: $box-shadow-3;
    }

    &:disabled {
        background-color: $disabled-color-1;
    }

    &:active {
        transform: scale(0.9);
    }
}

@media (max-width: 800px) {
    .button {
        width: 100% !important;
        max-width: 100% !important;
    }
}