@import './theme.scss';

.side-panel-button {
    color: $bg-color-2;
    background-color: $color-1;
    transition: all 0.3s ease-in-out;
    width: calc(100% - 45px);
    padding: 15px 30px;
    padding-left: 15px;
    border-radius: 5px;

    display: flex;
    gap: 8px;
    align-items: center;

    cursor: pointer;
    font-weight: 600;

    .icon {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
        }
    }

    &:hover {
        background-color: $bg-color-2;
        color: $color-1;

        .icon {
            img {
                filter: brightness(0); // Turn it black!
            }
        }
    }

    &:active {
        transform: scale(0.9);
    }
}

#active {
    background-color: $a-color-2;
    color: $bg-color-1;
}