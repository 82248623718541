@import './theme.scss';

.customer-list-item {
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 25px;
    border-radius: 8px;
    border: $border-1;
    cursor: pointer;

    &:hover {
        border-color: $bg-color-2;
    }

    .dp {
        width: 50px;
        height: 50px;
        background-position: center;
        background-size: cover;
        border-radius: 100%;
    }

    .details {
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-size: 0.8rem;
        font-weight: 500;

        .name {
            font-size: 1rem;
            font-weight: 600;
        }

        .email {
            font-size: 0.8rem;
            font-weight: 400;
        }
    }
}