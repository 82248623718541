@import "./theme.scss";

.view {
    padding: $padding-1;
    padding-left: 50px;
    padding-right: 50px;

    .page-loader {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: $padding-2;
        padding-bottom: $padding-2;
    }
}

@media (max-width: 800px) {
    .view {
        padding: $padding-2;
    }
}