@import './theme.scss';

.copy-bar {
    margin-top: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    gap: 15px;
    padding-bottom: 8px;
    padding-top: 8px;
    background-color: $bg-color-2;
    color: $color-1;
    font-size: 0.7rem;

    .copy-link {
        color: $color-1 !important;
    }
}

@media (max-width: 600px) {
    .copy-bar {
        font-size: 0.5rem;
    }
}