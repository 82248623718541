@import './theme.scss';

.order-list-item {
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 25px;
    border-radius: 8px;
    border: $border-1;
    cursor: pointer;
    align-items: center;

    justify-content: space-between;

    &:hover {
        border-color: $bg-color-2;
    }

    .order-type {
        font-size: 1rem;
        font-weight: bold;

        .icon {
            margin-right: 5px;
        }

        .address {
            margin-top: 5px;
            font-size: 0.8rem;
            font-weight: normal;
        }
    }

    .address {
        font-size: 1rem;
        font-weight: bold;
    }

    .details {
        display: flex;
        flex-direction: row;
        gap: 25px;
        font-size: 0.8rem;
        font-weight: 500;

        .row {
            display: flex;
            gap: 25px;
        }
    }
}

@media (max-width: 800px) {
    .order-list-item {
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
    }
}