@import './theme.scss';

.modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: 1000000;

    .modal-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        pointer-events: all;
    }

    .modals {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        .modal {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: all;
            border: $border-1;

            .modal-inner {
                background: $bg-color-1;
                border-radius: 15px;
                box-shadow: $box-shadow-1;
                min-width: 450px;
                overflow: hidden;
                overflow-y: scroll;

                max-height: 80vh;

                .modal-content {
                    .form {
                        margin-top: 0px;
                    }
                }

                .modal-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 45px;

                    .modal-title {
                        font-size: 24px;
                        font-weight: bold;
                        color: $color-1;
                    }

                    .modal-close {
                        width: 25px;
                        height: 100%;
                        cursor: pointer;

                        display: flex;
                        justify-content: center;

                        transition: all 0.2s ease-in-out;

                        svg {
                            width: 100%;
                            height: 100%;
                            color: $color-1;
                        }

                        &:hover {
                            svg {
                                color: $a-color-1;
                            }
                        }
                    }
                }
            }
        }
    }
}