@import 'theme.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;

    color: $color-1;
    background-color: $color-1;

    font-family: 'Helvetica', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 10pt;
}

label {
    font-size: 10pt !important;
    line-height: 150%;
    font-weight: bold;
}

h1 {
    font-family: 'Helvetica Bold';
}

a {
    color: $a-color-1 !important;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    opacity: 1;

    &:hover {
        opacity: 1;
        text-decoration: underline;
    }

    &:visited {
        color: $a-color-1 !important;
    }

    &:active {
        color: $a-color-1 !important;
    }
}

#accent-bold {
    color: $a-color-1;
    font-weight: bold;
}


#accent {
    color: $a-color-1;
}


#fake-link {
    display: flex;
    gap: 10px;
    color: $a-color-1;
    text-decoration: underline;
    align-items: center;

    #copy-me {

        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;

        border-radius: 5px;
        background-color: $bg-color-2;
        border: $border-1;
        color: $color-1;

        font-size: 0.5rem;
        transition: all 0.2s ease-in-out;

        cursor: pointer;

        &:hover {
            background-color: $a-color-1;
            color: $bg-color-2;
        }
    }
}

@font-face {
    font-family: 'Helvetica Bold';
    src: url('../../public/fonts/HelveticaNowDisplay-Bold.woff2') format('woff2'),
        url('../../public/fonts/HelveticaNowDisplay-Bold.woff') format('woff'),
        url('../../public/fonts/HelveticaNowDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Medium';
    src: url('../../public/fonts/HelveticaNowDisplay-Medium.woff2') format('woff2'),
        url('../../public/fonts/HelveticaNowDisplay-Medium.woff') format('woff'),
        url('../../public/fonts/HelveticaNowDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../../public/fonts/HelveticaNowDisplay-Regular.woff2') format('woff2'),
        url('../../public/fonts/HelveticaNowDisplay-Regular.woff') format('woff'),
        url('../../public/fonts/HelveticaNowDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poynter';
    src: url('../../public/fonts/PoynterOldstyleDisplay-SemiBdIt.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Poynter Serif Reading Edge Bold';
    src: url('../../public/fonts/PoynterSerifReadingEdge-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}