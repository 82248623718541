@import "./theme.scss";

.select-orgs {

    .orgs {
        margin-top: 15px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        max-height: 600px;
        overflow-y: scroll;

        .org {
            border: $border-1;
            padding: 8px;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
                background-color: $a-color-1;
                color: $color-2;
            }
        }

        #selected {
            background-color: $a-color-1;
            color: $color-2;

        }
    }
}