@import "./theme.scss";

.staff-member-list-item {
    justify-content: flex-start;
    display: flex;
    gap: 15px;
    align-items: center;
    border-radius: 8px;
    border: $border-1;
    cursor: pointer;
    padding: 15px;

    &:hover {
        border-color: $bg-color-2;
    }

    .dp {
        width: 50px;
        height: 50px;
        background-position: center;
        background-size: cover;
        border-radius: 100%;
    }

    .staff-details {
        display: flex;
        flex-direction: column;
        gap: 8px;


    }
}