@import "./theme.scss";

/* Styles for the integration-cards container */
.integration-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

/* Styles for the integration-card */
.integration-card {
    width: 80px;
    height: 80px;
    border: $border-1;
    border-radius: 8px;
    padding: 10px;
    margin: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        box-shadow: $box-shadow-3;
    }
}

/* Styles for the icon */
.integration-card svg {
    font-size: 1.25rem;
    margin-bottom: 15px;
}

/* Styles for the integration-card-title */
.integration-card-title {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
}