@import './theme.scss';

.package-option-list-item {
    position: relative;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    border-radius: 8px;
    border: 1px solid $bg-color-2;
    cursor: pointer;

    &:hover {
        border-color: $color-1;
    }

    .option {
        display: flex;
        gap: 5px;
    }

    .details {
        display: flex;
        flex-direction: row;
        gap: 15px;
        font-size: 0.8rem;
        font-weight: 500;

        .checkbox-wrapper {
            padding-bottom: 12px;
            margin-top: auto;
        }

        .name {
            font-size: 1rem;
            font-weight: 600;
        }

        .desc {
            font-size: 0.8rem;
            font-weight: 400;
        }
    }

    .remove {
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: $a-color-1;
        color: $bg-color-1;
        width: 35px;
        height: 35px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: $e-color;
        }
    }
}