@import './theme.scss';

.package-list-item {
    position: relative;
    // padding: 15px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    border-radius: 8px;
    border: $border-1;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    height: 65px;

    .left {
        width: 100%;
        display: flex;
        gap: 15px;
        align-items: center;

        .custom-package {
            height: 35px;
            padding-left: 15px;
            padding-right: 15px;
            border-radius: 5px;
            border: $border-1;
            border-color: $w-color;
            color: $w-color;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .row {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .name {
                font-size: 1.1rem;
                font-weight: bold;
                margin: 0;
            }
        }
    }

    .right {
        // width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 15px;

        .detail {
            display: flex;
            flex-direction: column;
            gap: 5px;
            font-size: 0.8rem;
            font-weight: 500;

            .name {
                font-size: 1rem;
                font-weight: 600;
            }

            .desc {
                font-size: 0.8rem;
                font-weight: 400;
            }
        }
    }

    .remove {
        position: absolute;
        top: -5px;
        right: -15px;
        width: 25px;
        height: 25px;

        color: $color-1;

        &:hover {
            color: $e-color;
        }

        svg {
            height: 70%;
        }
    }

    &:hover {
        border-color: $color-1;
        // color: $alt-color;
        // background-color: $accent;
    }

    .dp {
        width: 50px;
        height: 50px;
        background-position: center;
        background-size: cover;
        border-radius: 100%;
    }


}