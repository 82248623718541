@import './theme.scss';

.alert-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;

    .alert-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .alerts {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        .alert {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            .inner {
                max-width: 480px;
                background-color: $bg-color-1;
                border: $border-1;
                box-shadow: $box-shadow-1;
                border-radius: 15px;
                pointer-events: all;
                padding: 30px;

                h2 {
                    margin: 0px;
                }

                .buttons {
                    margin-top: 30px;
                    display: flex;
                    gap: 15px;
                }
            }
        }
    }
}