@import './theme.scss';

.drop-down-menu-wrapper {
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0px;
    bottom: 0;

    width: 100%;
    height: calc(100% + 10px);
    opacity: 1;

    .hover-helper {
        position: absolute;
        left: -40px;
        bottom: calc(-50%);
        width: 0;
        height: 0;
        border-top: 40px solid transparent;
        border-bottom: 40px solid transparent;
        border-right: 40px solid blue;
        opacity: 0;
    }

    .menu {
        border-radius: 8px;
        padding: 15px;
        position: absolute;
        top: calc(100% - 0px);
        min-width: 170px;
        width: calc(100%);
        right: -1px;
        z-index: 100;
        background-color: $bg-color-1;
        border: $border-1;
        box-shadow: $box-shadow-2;

        display: flex;
        flex-direction: column;

        gap: 5px;

        .item {
            padding: 8px;
            border-radius: 6px;

            display: flex;
            align-items: center;
            gap: 8px;
            transition: all 0.3s ease-in-out;

            color: $color-1;

            &:hover {
                background-color: $a-color-1;
                color: $bg-color-1;
            }
        }
    }
}