@import './theme.scss';

.view-header-menu {
    min-width: fit-content;
    max-width: fit-content;
    height: calc(100% - 25px);
    padding: 10px;
    background-color: $color-2;
    border: $border-1;
    box-shadow: $box-shadow-3;
    border-radius: 8px;
    pointer-events: all;
    display: flex;
    gap: 10px;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    position: relative;
    // font-size: 0.7rem;
    justify-content: space-between;
    align-items: center;


    &:hover {
        background-color: $bg-color-1;
        box-shadow: $box-shadow-3;
    }

    .label {
        display: flex;
        gap: 5px;
        align-items: center;
    }
}