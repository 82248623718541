@import "./theme.scss";

.remax-listing-list-item {
    display: flex;
    justify-content: space-between;

    border: $border-1;
    border-radius: 5px;
    border-radius: 8px;

    padding: 15px;

    font-weight: 1rem;

    transition: 0.2s ease-in-out all;
    cursor: pointer;

    .address {
        font-weight: 1.2rem;
        font-weight: bold;
    }

    &:hover {
        border-color: $a-color-1;
    }
}