@import "./theme.scss";

.selectable-row-con {
    display: flex;
    gap: 30px;
}

.selectable_list {
    width: 100%;
    display: flex;
    gap: 8px;
    flex-direction: column;
    margin-bottom: 30px;

    .selectable_list_items {
        display: flex;
        gap: 8px;
        flex-direction: column;

        max-height: 400px;
        overflow-y: scroll;

        .customer-list-item {
            padding: 15px;

            display: flex;
            gap: 8px;

            .dp {
                max-width: 20px;
                max-height: 20px;
            }
        }

        .package-list-item {
            display: flex;
            justify-content: space-between;

            .package-details {
                display: flex;
                gap: 3px;
                align-items: center;
                margin-top: 5px;
            }

            .package-name {
                font-weight: bold;
                font-size: 1rem;
            }

            .package-price {
                display: flex;
                gap: 3px;
                align-items: center;
                margin-top: 5px;
            }
        }

        #selected {
            background-color: $a-color-1;
            color: $color-2;
        }
    }
}