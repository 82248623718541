@import "./theme.scss";

.container {
    width: 100%;
    padding: 15px;

    h2 {
        margin: 0;
    }

    .error {
        margin-top: 15px;
    }
}

@media (max-width: 800px) {
    .container {
        padding: 0px;
    }
}