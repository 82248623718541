@import "./theme.scss";

.gallery-grid {
    display: flex;
    gap: 15px;
    flex-direction: column;

    img {
        width: 100%;
    }
}