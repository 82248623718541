@import './theme.scss';
@import 'react-datepicker/dist/react-datepicker.css';

.date-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;

    label {
        font-size: 10pt !important;
        line-height: 150%;
        font-weight: bold;
    }

    input {
        width: calc(100% - 35px);
        height: 100%;
        color: $color-1;
        padding: 1rem;
        border: $border-1;
        border-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s,
            box-shadow 0.2s;
        outline: none;
        font-family: 'Nunito', sans-serif;

        // Make it so this element goes to end of flex
        margin-top: auto;
    }

    input:hover {
        border-color: $a-color-1;
    }

    input:focus {
        border-color: $a-color-1;
        box-shadow: $box-shadow-3;
    }

    // For the icon password
    .reveal-con {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: 15px;
        margin-right: 15px;
    }
}

.react-datepicker__tab-loop {
    position: absolute;
}

.react-datepicker__aria-live {
    display: none;
    position: unset;
}

.react-datepicker-popper {
    // width: 250px;
}

.react-datepicker {
    border-radius: 16px;
    width: 100%;
    border: none;
}

.react-datepicker__month {
    padding: 10px;
    // margin: 0;
    font-size: 0.8rem;
}

.react-datepicker__day--keyboard-selected {
    background-color: $a-color-1 !important;
    color: $bg-color-2;
}

.react-datepicker__month-container {
    background-color: $bg-color-1;
    color: $color-1;
    border-radius: 8px;
    border: none;
    box-shadow: $box-shadow-3;
    border: $border-1 !important;
    // border: none !important;
    outline: none !important;
    width: 100%;
}

.react-datepicker__header {
    background-color: $bg-color-1;
    padding-bottom: 0px;
    padding-top: 15px;
    border-bottom: none;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: $color-1;
}

.react-datepicker__current-month {
    font-size: 1rem;
    margin-bottom: 15px;
}

.react-datepicker__day-name {
    width: 30px;
    color: #717171;
}

.react-datepicker__triangle::before,
.react-datepicker__triangle::after {
    display: none;
}

.react-datepicker__day {
    border-radius: 100% !important;
    // background-color: red;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
}

.react-datepicker__day--selected {
    background-color: $a-color-1 !important;
}

.react-datepicker__navigation {
    border: none;
    top: 10px;
}