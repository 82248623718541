@import "./theme.scss";

.org-list-select {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 30px;

    .loader-con {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
    }

    .org-list {
        display: flex;
        flex-direction: column;
        max-height: 450px;
        overflow-y: scroll;
        gap: 8px;

        #selected {
            background-color: $a-color-1;
            color: $color-2;
        }

        .org-list-item {
            border: $border-1;
            padding: 15px;
        }
    }

}