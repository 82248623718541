@import './theme.scss';

.checkbox-wrapper {
    cursor: pointer;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    input {
        display: none;
    }

    .check {
        width: 20px;
        height: 20px;
        border: 1px solid $border-color;
        background-color: white;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    label {
        cursor: pointer;
        padding-bottom: 2px;

        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }
}