@import "./theme.scss";

.textarea-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;

    label {
        font-size: 10pt !important;
        line-height: 150%;
        font-weight: bold;
    }

    textarea {
        height: 100%;
        color: $color-1;
        padding: 1rem;
        border: $border-1;
        border-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s,
            box-shadow 0.2s;
        outline: none;
        font-family: 'Nunito', sans-serif;
        resize: none;
    }

    textarea::placeholder {
        font-style: italic;
    }

    textarea:hover {
        border-color: $a-color-1;
    }

    textarea:focus {
        border-color: $a-color-1;
        box-shadow: $box-shadow-3;
    }
}