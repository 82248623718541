@import "./theme.scss";


.team-list-item {
    justify-content: flex-start;
    display: flex;
    gap: 15px;
    align-items: center;
    border-radius: 8px;
    border: $border-1;
    cursor: pointer;
    padding: 15px;
    justify-content: space-between;

    &:hover {
        border-color: $bg-color-2;
    }

    .team-list-item-name {
        font-weight: 600;
        font-size: 1rem;
    }

    .details {
        display: flex;
        gap: 15px;
    }

}