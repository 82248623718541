@import './theme.scss';

.view-header {
    display: flex;
    justify-content: space-between;
    height: 50px;
    margin-bottom: 30px;

    .left {
        display: flex;
        gap: 15px;
        align-items: center;
        max-width: unset;
        min-width: unset;

        .title {
            font-size: 24px;
            font-weight: bold;
            color: $color-1;
        }

        .back {
            background-color: $color-2;
            width: 35px;
            height: 35px;

            min-width: 35px;
            min-height: 35px;
            max-width: 35px;
            max-height: 35px;

            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            transition: all 0.2s ease-in-out;
            cursor: pointer;

            &:hover {
                background-color: $a-color-1;
                color: $color-2;
            }
        }
    }

    .right {
        display: flex;
        gap: 15px;
    }
}

@media (max-width: 800px) {
    .view-header {
        margin-left: 50px;

        .right {
            .auth-bubble {
                display: none;
            }
        }
    }
}