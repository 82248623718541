@import "./theme.scss";

.clickup-options {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;

    .clickup-option {
        padding: 8px 8px;
        border: $border-1;
        border-radius: 4px;

        &:hover {
            border-color: $color-1
        }

    }

    #selected {
        background-color: $a-color-1;
        color: $color-2;
    }
}