@import "./theme.scss";

.upload-image-wrapper {
    margin-bottom: 15px;

    label {
        display: block;
        margin-bottom: 15px;
    }

    .image-to-click {
        position: relative;
        cursor: pointer;
        width: fit-content;

        &:hover {
            .overlay {
                opacity: 1;
                box-shadow: $box-shadow-1;
            }
        }

        .profile_img {
            min-width: 350px;
            min-height: 350px;
            width: 350px;
            height: 350px;
            border-radius: 50%;
            object-fit: cover;
            overflow: hidden;
            border: $border-1;
            background-color: $a-color-1;
        }

        .overlay {
            position: absolute;
            top: 30px;
            right: 30px;
            background-color: $color-1;
            color: $bg-color-1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1rem;
            transition: all 0.2s ease-in-out;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            opacity: 0;

            &:hover {
                background-color: $a-color-1;
                color: $bg-color-2;
            }
        }
    }

    .hidden-button {
        display: none;
    }
}