@import "./index.scss";

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;

    .form-flex-2 {
        // 2x2 grid
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }
}