@import "./theme.scss";


.org-list-item {
    justify-content: flex-start;
    display: flex;
    gap: 15px;
    align-items: center;
    border-radius: 8px;
    border: $border-1;
    cursor: pointer;
    padding: 15px;

    &:hover {
        border-color: $bg-color-2;
    }

    .company-logo {
        width: 50px;
        height: 50px;
        background-position: center;
        background-size: cover;
        border-radius: 100%;
        border-color: $bg-color-2;


        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100%;
        }
    }

    .company-details,
    .stats {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .stat {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
    }
}