@import "./theme.scss";

.team-members {
    margin-top: 8px;
    margin-bottom: 30px;
    max-height: 450px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .customer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $bg-color-2;
        color: $color-1;
        border-radius: $border-radius-2;
        padding: 8px;

        .add-btn {
            padding: 5px;
            border-radius: 100%;
            border: $border-1;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                background-color: $a-color-1;
                color: $bg-color-1;
            }
        }
    }
}