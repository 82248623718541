@import './theme.scss';

.dash-layout {
    position: relative;
    width: 100%;
    max-width: 100vw;
    display: flex;
    min-height: 100vh;
    background-color: $bg-color-1;

    .content {
        width: 100%;
    }

    .layout-header {
        position: absolute;
        top: 0;
        left: 200px;
        right: 0;

        height: 80px;
        display: flex;
        justify-content: right;

        pointer-events: none;
    }

    .inner-menu-items {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 5px;
    }
}