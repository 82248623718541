@import './theme.scss';

.text {
    display: flex;
    flex-direction: column;
    // gap: 5px;

    label {
        font-weight: bold;
        font-size: 0.7rem;
    }
}