@import "./theme.scss";

.options-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: -30px;
    margin-top: 30px;
    align-items: center;

    .title {
        font-weight: bold;
    }

    .actions {
        display: flex;
        gap: 10px;

        .action {
            color: $a-color-1;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.selectable_list {

    .org-list-item {}

    #selected {
        background-color: $a-color-1;
        color: $color-2;
    }
}