@import "./theme.scss";

.package-left-item {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
        margin-top: 5px;
        margin-bottom: 5px;

    }
}

.order-header-image {
    margin-top: 40px;
    width: 100%;
    height: 500px;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    margin-bottom: 60px;
    box-shadow: $box-shadow-3;
    background-color: $a-color-1;

    position: relative;

    .order-header-image-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        color: $color-2;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.package-right {
    min-width: 300px;
}

.performance-metrics {
    margin-top: -30px;

    h3 {
        margin-top: 30px;
    }

    .performance-metrics-row {
        display: flex;
        justify-content: space-between;
    }
}

.performance-metrics-p {
    margin-top: 0px;
}

.address-field {
    margin-top: 20px;

    h1 {
        margin-top: 5px;
    }
}

.general-field {
    margin-top: 30px;

    h2 {
        margin-top: 5px;
        font-weight: normal;
        font-size: 1rem;
    }
}

.note-fields {
    margin-top: 30px;

    h2 {
        margin-top: 5px;
        font-weight: normal;
        font-size: 1rem;
    }
}

.image-uploads {
    display: flex;
    gap: 15px;
    overflow-x: scroll;
    padding-top: 30px;
    margin-top: -15px;
    padding-bottom: 15px;
    margin-bottom: -15px;
    padding-left: 5px;
    margin-left: -5px;
    padding-right: 5px;
    margin-right: -5px;
}

.date-fields {
    margin-top: 30px;

    h2 {
        margin-top: 5px;
        font-weight: normal;
        font-size: 1rem;
    }
}

.image-fields {
    margin-top: 30px;
}


#no-margin {
    margin: 0;
    padding: 0;

    .header-details {
        margin-bottom: -15px !important;
    }
}

.view-report-section {
    margin-top: 60px;
    background-color: $a-color-1;
    color: $bg-color-2;
    padding: 30px;
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    box-shadow: $box-shadow-3;


    h2 {
        width: 100%;
        margin: 0;
    }

    p {
        margin: 0;
        width: 100%;
    }

    .secondary-button {
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
        max-width: 200px;
    }
}

.bottom-section {
    margin-bottom: 60px;
}

@media (max-width: 1000px) {
    .performance-metrics-row {
        flex-direction: column;

    }
}

@media (max-width: 800px) {
    .package-right {
        min-width: 0px;
    }
}


.media-grid-wrapper {
    margin-top: 30px;
    margin-bottom: 60px;
    width: 100%;

    .media-grid {
        margin-top: 8px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 100px));
        gap: 15px;

        .media-grid-item {
            position: relative;

            img {
                border-radius: 3px;
                width: 100%;
                height: 100px;
                object-fit: cover;
            }

            &:hover {
                .remove-btn {
                    // display: flex;
                    display: block;
                }
            }

            .remove-btn {
                display: none;
                position: absolute;
                top: 5px;
                right: 5px;
                background-color: $bg-color-1;
                color: $color-1;
                transition: all 0.2s ease-in-out;
                padding: 5px;
                border-radius: 2px;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &:hover {
                    background-color: $e-color;
                    color: $color-2;
                }
            }

        }


    }
}